<template>
  <div id="app" :class="{ admin: $route.meta.hideHeaderFooter }">
    <AppHeader v-if="!$route.meta.hideHeaderFooter" />
    <AdminHeader v-if="$route.meta.hideHeaderFooter" />
    <router-view />
    <AppFooter v-if="!$route.meta.hideHeaderFooter" />
  </div>
</template>




<script>
import AppHeader from './common/AppHeader.vue'
import AdminHeader from './common/AdminHeader.vue'
import AppFooter from './common/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AdminHeader
  }
}
</script>

<style>
@import url('../src/assets/css/default.css');
@import url('../src/assets/css/page.css');
</style>
