<template>
    <div id="header">
        <div class="header-container content-wrap">
            <a href="/"><img src="@/assets/images/logo.svg"></a>
            <ul class="header-menu__list">
                <li class="header-menu__item">
                    <router-link to="/" exact active-class="active">HOME</router-link>
                </li>
                <li class="header-menu__item">
                    <router-link to="/CoinPurchase" active-class="active">코인구매대행</router-link>
                </li>
                <li class="header-menu__item">
                    <router-link to="/CoinSales" active-class="active">코인판매대행</router-link>
                </li>
                <li class="header-menu__item">
                    <router-link to="/Mypage" active-class="active">접수내역</router-link>
                </li>
                <li class="header-menu__item">
                    <router-link to="/Notice" active-class="active">공지사항</router-link>
                </li>
            </ul>
            <button class="mobile-menu" @click="openMenu()"><img src="@/assets/images/menu-ico.svg"></button>
        </div>
        <div class="mobile-menu__all" v-if="isMenuOpen">
            <div class="mobile-menu__header">
                <a href="/"><img src="@/assets/images/logo.svg"></a>
                <button class="mobile-menu" @click="closeMenu()"><img src="@/assets/images/menu-close.svg"></button>
            </div>
            <ul class="mobile-menu__list">
                <li class="mobile-menu__item" @click="closeMenu()">
                    <router-link to="/CoinPurchase" active-class="active">코인구매대행</router-link>
                </li>
                <li class="mobile-menu__item" @click="closeMenu()">
                    <router-link to="/CoinSales" active-class="active">코인판매대행</router-link>
                </li>
                <li class="mobile-menu__item" @click="closeMenu()">
                    <router-link to="/Mypage" active-class="active">접수내역</router-link>
                </li>
                <li class="mobile-menu__item" @click="closeMenu()">
                    <router-link to="/Notice" active-class="active">공지사항</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: '',
    components: {
    },

    data() {
        return {
            isMenuOpen: false
        };
    },

    components: {

    },

    mounted() {
 
    },

    methods: {
        openMenu() {
            this.isMenuOpen = true;
        },
        closeMenu() {
            this.isMenuOpen = false;
        }
    }
}

</script>