<template>
    <div id="admin-header">
        <div id="login-check" v-if="login">
            <div class="login-container">
                <h2 class="login-title">관리자 로그인</h2>
                <table class="login-table">
                    <tbody>
                        <tr>
                            <th>아이디</th>
                            <td><input type="text" v-model="id"></td>
                        </tr>
                        <tr>
                            <th>비밀번호</th>
                            <td><input type="password" v-model="password"></td>
                        </tr>
                    </tbody>
                </table>
                <div class="login-button__box">
                    <button class="login-button" @click="userLogin()">로그인</button>
                    <button class="login-button" @click="routerBack()">돌아가기</button>
                </div>
            </div>
        </div>
        <h2><img src="@/assets/images/logo.svg"></h2>
        <ul class="admin-menu__list">
            <li class="admin-menu__item" :class="{ active: $route.name === 'MemberAdmin' }">
                <router-link :to="{ name: 'MemberAdmin' }">회원 관리</router-link>
            </li>
            <li class="admin-menu__item" :class="{ active: $route.name === 'ListAdmin' }">
                <router-link :to="{ name: 'ListAdmin' }">접수내역 관리</router-link>
            </li>
            <li class="admin-menu__item" :class="{ active: $route.name === 'BoardAdmin' }">
                <router-link :to="{ name: 'BoardAdmin' }">공지사항 관리</router-link>
            </li>
        </ul>
        <button @click="goToHomepage()" class="admin-hompage__button">홈페이지 이동</button>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: '',
    components: {
    },

    data() {
        return {
            id: "",
            password: "",
            login: true
        };
    },

    components: {

    },

    mounted() {
        const accessToken = sessionStorage.getItem("access_token");

        if (accessToken) {
            this.login = false;

        } else {
            console.log("세션이 없습니다. 로그인 페이지로 이동합니다.");
            this.login = true
        }
    },

    methods: {
        goToHomepage() {
            this.$router.push('/'); // '/' 경로로 이동
        },
        userLogin() {
            this.$axios
                .post(
                    `https://trumpcoin2020.com/api/users/login`,
                    {
                        username: this.id,
                        password: this.password,
                        keepLogin: 0,
                        user_social_id: "string"
                    }
                )
                .then((response) => {
                    if (response.status == 200) {
                        const { access_token, expire_date, token_type, user_id } = response.data;

                        sessionStorage.setItem("access_token", access_token);
                        sessionStorage.setItem("expire_date", expire_date);
                        sessionStorage.setItem("token_type", token_type);
                        sessionStorage.setItem("user_id", user_id);
                        this.login = false
                        setTimeout(() => {
                            location.reload();
                        }, 1000);
                    }
                    else {
                        alert("로그인 정보가 일치하지 않습니다.")
                        this.id = "";
                        this.password = "";

                    }
                })
                .catch((error) => {
                    alert("로그인 정보가 일치하지 않습니다.")
                    this.id = "";
                    this.password = "";
                });
        },


        routerBack() {
            this.$router.push({ name: 'index' })
        }
    }
}

</script>